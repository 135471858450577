import { ColumnDef, Row } from '@tanstack/react-table';
import { cn, compileFullName, getColorForServiceRole, transformApiDateToViewDate } from '@/lib/utils';
import { Trash2 } from 'lucide-react';
import { Chips, toast, ToastTypeEnums } from '@/components/common';
import { Colors, DOCUMENT_TYPE_TYPES } from '@/common/enums';
import { Client, Document, Service } from '@/views/all-documents/interfaces/document.interface';
import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { AcquireFileType } from '@/views/all-documents/acquire-file-types';
import { DOCUMENT_STATUS_COLORS, USER_ROLES_COLORS } from '@/common/constants';
import { ROLE_TYPES } from '@/lib/RBAC/enums/role-types';

type Params = {
  t: any;
  userId?: string;
  onPreviewClient: (client: Client) => void;
  onPreviewService: (service: Service) => void;
  onConfirmRemove: (row: Row<Document>) => void;
  currentRole: ROLE_TYPES | null;
  isCanRemove?: boolean;
};

export const getMyDocumentsColumns = ({
  t,
  onConfirmRemove,
  onPreviewClient,
  onPreviewService,
  userId,
  currentRole,
  isCanRemove = true,
}: Params): ColumnDef<Document>[] => {
  const columns: ColumnDef<Document>[] = [
    {
      id: 'Report Type',
      accessorKey: 'type',
      header: () => <span>{t('Tables.reportType')}</span>,
      size: 150,
      meta: {
        columnName: t('Tables.reportType'),
      },
      enableSorting: false,
      enablePinning: false,
    },
    {
      id: 'Client',
      accessorKey: 'client',
      header: () => <span>{t('Tables.client')}</span>,
      meta: {
        columnName: t('Tables.client'),
      },
      cell: ({ row }) => {
        if (row.original.client) {
          return (
            <div className="flex flex-row flex-wrap gap-1 py-2">
              <Chips
                onClickAction={(e) => {
                  onPreviewClient(row.original.client);
                  e.stopPropagation();
                }}
                color={Colors.indigo}
                title={compileFullName(row.original.client)}
              />
            </div>
          );
        }
        return <></>;
      },
      enableSorting: true,
      enablePinning: false,
      size: 250,
    },
    {
      id: 'Assignee',
      accessorKey: 'therapist',
      header: () => <span>{t('Tables.assignee')}</span>,
      meta: {
        columnName: t('Tables.assignee'),
      },
      cell: ({ row }) => {
        if (row.original.therapist) {
          return (
            <div className="flex flex-row flex-wrap gap-1 py-2">
              <Chips color={USER_ROLES_COLORS[ROLE_TYPES.Therapist]} title={compileFullName(row.original.therapist)} />
            </div>
          );
        }
        return <></>;
      },
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
    {
      id: 'Service',
      accessorKey: 'service',
      header: () => <span>{t('Tables.service')}</span>,
      meta: {
        columnName: t('Tables.service'),
      },
      cell: ({ row }) => {
        if (row.original.service) {
          return (
            <div className="flex flex-row flex-wrap gap-1 py-2">
              <Chips
                onClickAction={
                  userId === row.original.therapist?.id
                    ? (e) => {
                        onPreviewService(row.original.service as Service);
                        e.stopPropagation();
                      }
                    : undefined
                }
                color={getColorForServiceRole(row.original.service.serviceRole.title)}
                title={row.original.service.name}
              />
            </div>
          );
        }
        return <></>;
      },
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
    {
      id: 'Status',
      accessorKey: 'status',
      header: () => <span>{t('Tables.status')}</span>,
      meta: {
        columnName: t('Tables.status'),
      },
      cell: ({ row }) => {
        if (row.original.status) {
          return (
            <div className="flex flex-row flex-wrap gap-1 py-2">
              {/* @TODO: Change Past due to Expired on BE */}
              <Chips
                color={DOCUMENT_STATUS_COLORS[row.original.status]}
                title={row.original.status}
              />
            </div>
          );
        }
        return <></>;
      },
      enableSorting: false,
      enablePinning: false,
      size: 150,
    },
    {
      id: 'Due Date',
      accessorFn: (row) => transformApiDateToViewDate(row.expiryDate),
      header: () => <span>{t('Tables.dueDate')}</span>,
      meta: {
        columnName: t('Tables.dueDate'),
      },
      enableSorting: true,
      enablePinning: false,
      size: 120,
    },
    {
      id: 'Notes File',
      accessorKey: 'notes',
      header: () => <span>{t('Tables.notesFile')}</span>,
      meta: {
        columnName: t('Tables.notesFile'),
      },
      cell: ({ row }) => {
        if (row.original.notesFileUrl) {
          return (
            <span
              className="text-primary"
              onClick={(e) => {
                openFile(t, row.original.id, AcquireFileType.Notes);
                e.stopPropagation();
              }}
            >
              {row.original.notesFileName ? row.original.notesFileName : t('Buttons.notesFile')}
            </span>
          );
        }
        return <></>;
      },
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
    {
      id: 'ReportFile',
      accessorKey: 'reportFile',
      header: () => <span>{t('Tables.reportFile')}</span>,
      meta: {
        columnName: t('Tables.reportFile'),
      },
      cell: ({ row }) => {
        if (row.original.fileUrl) {
          const isDisableOpenReportFile =
            (currentRole === ROLE_TYPES.Assistant || currentRole === ROLE_TYPES['Assistant Limited']) &&
            (row.original.type === DOCUMENT_TYPE_TYPES.Discharge || row.original.type === DOCUMENT_TYPE_TYPES.Progress);
          return (
            <span
              className={cn('text-primary', isDisableOpenReportFile && 'cursor-not-allowed opacity-30')}
              onClick={(e) => {
                if (!isDisableOpenReportFile) {
                  openFile(t, row.original.id, AcquireFileType.Report);
                }
                e.stopPropagation();
              }}
            >
              {row.original.fileName ? row.original.fileName : t('Buttons.reportFile')}
            </span>
          );
        }
        return <></>;
      },
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
  ];

  if (isCanRemove) {
    columns.push({
      id: 'Remove',
      enableSorting: false,
      cell: ({ row }) => (
        <Trash2
          onClick={(e) => {
            if (!row.original.deletedAt) {
              onConfirmRemove(row);
            }
            e.stopPropagation();
          }}
          className={cn(
            'w-[18px] cursor-pointer text-gray-400',
            row.original.deletedAt && 'cursor-not-allowed opacity-45'
          )}
        />
      ),
      size: 50,
    });
  }

  return columns;
};

const openFile = async (t: any, documentId: string, type: AcquireFileType) => {
  const { data } = await axiosInterceptorInstance.get(`documents/${documentId}/acquire/${type}`);
  if (data?.url) {
    window.open(data.url, '_blank', 'noopener,noreferrer');
  } else {
    toast({
      title: t('Toasts.warning'),
      typeIcon: ToastTypeEnums.WARNING,
      description: t('Toasts.notFoundFile'),
    });
  }
};
