'use client';
import Folder from './../../../public/icons/folder-blue.svg';
import Calendar from './../../../public/icons/calendar-blue.svg';
import Wallet from './../../../public/icons/wallet-money.svg';
import { useTranslations } from 'next-intl';
import { StatisticBadge } from '@/components/common';
import { TimesheetDetailsTable } from './table/timesheet-details-table';
import { useTimesheetQuery } from '@/hooks/fetchers/queries/timesheets/useTimesheetQuery';
import dayjs from '@/lib/dayjsConfig';
import { minutesToTime } from '@/lib/utils';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import { useIsAllowed } from '@/lib/RBAC';

export function TimesheetDetailsView({ timesheetId }: { timesheetId: string }) {
  const t = useTranslations();
  const { checkPermissions } = useIsAllowed();
  const { data: timesheet } = useTimesheetQuery(timesheetId);

  return (
    <main className="pl-4 pr-6">
      <h1 className="py-4 text-2xl font-bold">{t('Pages.Timesheets.titleDetails')}</h1>
      <div className="mb-6 flex flex-row space-x-4">
        <StatisticBadge
          size="lg"
          iconHeight={54}
          iconWidth={54}
          classNameIcon="p-3 ml-3"
          svgPath={Calendar}
          label={t('Pages.Timesheets.period')}
          value={
            timesheet
              ? `${dayjs(timesheet.periodFrom).format('MMM')} ${dayjs(timesheet.periodFrom).format('DD')} - ${dayjs(timesheet.periodTo).format('MMM')} ${dayjs(timesheet.periodTo).format('DD')}`
              : '---'
          }
        />
        {timesheet && timesheet.report ? (
          <>
            {checkPermissions([PERMISSIONS_TYPES.VIEW_TOTAL_PAY_METRIC]) && (
              <StatisticBadge
                size="lg"
                iconHeight={54}
                iconWidth={54}
                classNameIcon="p-3 ml-3"
                svgPath={Wallet}
                label={t('Pages.Timesheets.totalPay')}
                value={timesheet.report.totalPay ? `$${timesheet.report.totalPay.toFixed(2)}` : `---`}
              />
            )}
            <StatisticBadge
              size="lg"
              iconHeight={54}
              iconWidth={54}
              classNameIcon="p-3 ml-3"
              svgPath={Calendar}
              label={t('Pages.Timesheets.totalHours')}
              value={timesheet.report.totalDuration ? minutesToTime(timesheet.report.totalDuration) : `---`}
            />
            <StatisticBadge
              size="lg"
              iconHeight={54}
              iconWidth={54}
              classNameIcon="p-3 ml-3"
              svgPath={Folder}
              label={t('Pages.Timesheets.totalShifts')}
              value={timesheet.report.shiftsCount ? `${timesheet.report.shiftsCount}` : `---`}
            />
          </>
        ) : null}
      </div>

      <TimesheetDetailsTable
        timesheetId={timesheetId}
        status={timesheet?.status}
        period={
          timesheet
            ? `${dayjs(timesheet.periodFrom).format('MMM')} ${dayjs(timesheet.periodFrom).format('DD')} - ${dayjs(timesheet.periodTo).format('MMM')} ${dayjs(timesheet.periodTo).format('DD')}`
            : '---'
        }
      />
    </main>
  );
}
