'use client';
import { Button } from '@/components/ui';

import { SquarePlus } from 'lucide-react';
import { ScheduleHeader } from './schedule-header';
import dayjs from '@/lib/dayjsConfig';
import { createRef, useCallback, useState } from 'react';
import { cn, compileFullName } from '@/lib/utils';
import {
  FilterAccountsType,
  SelectAccountsDialog,
  SelectAccountsDialogRef,
  SelectAccountsFilterData,
} from './select-accounts.dialog';
import { useTranslations } from 'next-intl';
import { ScrollArea } from '@/components/common';
import { ScheduleAccount } from './schedule-account';
import { ShiftItem } from './shift-item';
import { useSchedulesQuery } from '@/hooks/fetchers/queries/shifts/useSchedulesQuery';
import * as _ from 'lodash';
import { PreviewShiftDialog, PreviewShiftDialogRef } from '../all-shifts/preview/preview-shift.dialog';
import { ManageShiftDialog, ManageShiftDialogRef } from '../all-shifts/manage/manage-shift.dialog';

const DEBOUNCE_DELAY = 500;

export function ScheduleView() {
  const t = useTranslations();
  const previewShiftDialogRef = createRef<PreviewShiftDialogRef>();
  const manageShiftDialogRef = createRef<ManageShiftDialogRef>();
  const [weekDays, setWeekDays] = useState<dayjs.Dayjs[]>([]);
  const [range, setRange] = useState<{ rangeFrom: string; rangeTo: string }>({
    rangeFrom: dayjs().startOf('week').toISOString(),
    rangeTo: dayjs().endOf('week').add(1, 'day').toISOString(),
  });
  const [selectAccounts, setSelectAccounts] = useState<SelectAccountsFilterData>({
    selectedClients: [],
    selectedTherapists: [],
    type: FilterAccountsType.CLIENTS,
  });
  const selectAccountsDialogRef = createRef<SelectAccountsDialogRef>();

  const { data: shifts, refetch: refetchSchedules } = useSchedulesQuery({
    rangeFrom: range.rangeFrom,
    rangeTo: range.rangeTo,
    therapistIdList: selectAccounts.selectedTherapists,
    clientIdList: selectAccounts.selectedClients,
  });

  const onChangeRangeHandler = useCallback(_.debounce(handleChangeRange, DEBOUNCE_DELAY), []);

  function getDatesBetween(startDate: dayjs.Dayjs, endDate: dayjs.Dayjs): dayjs.Dayjs[] {
    let dates = [];
    while (startDate.isBefore(endDate) || startDate.isSame(endDate, 'day')) {
      dates.push(startDate);
      startDate = startDate.add(1, 'day');
    }
    return dates;
  }

  function handleChangeRange(week: { start: dayjs.Dayjs; end: dayjs.Dayjs }) {
    const days = getDatesBetween(week.start, week.end);
    setRange({
      rangeFrom: days[0].toISOString(),
      rangeTo: days[6].add(2, 'day').toISOString(),
    });
  }

  function handleChangeWeek(week: { start: dayjs.Dayjs; end: dayjs.Dayjs }) {
    const days = getDatesBetween(week.start, week.end);
    setWeekDays(days);
  }

  function handleOpenChooseAccounts() {
    selectAccountsDialogRef.current?.open(selectAccounts);
  }

  function hideTherapistHandle(id: string) {
    if (selectAccounts.type === FilterAccountsType.CLIENTS) {
      setSelectAccounts((prev) => ({
        ...prev,
        selectedClients: prev.selectedClients.filter((clientId) => clientId !== id),
      }));
    } else if (selectAccounts.type === FilterAccountsType.THERAPISTS) {
      setSelectAccounts((prev) => ({
        ...prev,
        selectedTherapists: prev.selectedTherapists.filter((therapistId) => therapistId !== id),
      }));
    }
  }

  function handleChooseAccounts(accounts: SelectAccountsFilterData) {
    setSelectAccounts({
      type: accounts.type,
      selectedClients: accounts.type === FilterAccountsType.CLIENTS ? accounts.selectedClients : [],
      selectedTherapists: accounts.type === FilterAccountsType.THERAPISTS ? accounts.selectedTherapists : [],
    });
  }

  return (
    <>
      <ScheduleHeader
        onAddShift={() => manageShiftDialogRef.current?.open()}
        onChange={(value) => {
          handleChangeWeek(value);
          onChangeRangeHandler(value);
        }}
      />

      <div className="mt-2 grid grid-cols-[224px_repeat(7,_minmax(auto,1fr))] gap-1">
        <div className="flex flex-row items-center justify-center py-1">
          <Button variant="outline" size="sm" className="w-full" onClick={handleOpenChooseAccounts}>
            {t('Buttons.selectAccounts')} <SquarePlus className="ml-2 size-5 text-primary" />
          </Button>
        </div>
        {weekDays.map((day) => (
          <div key={day.format('DD/MM/YY')} className="flex flex-row items-center justify-center p-1">
            <span
              className={cn(
                'px-1 py-1 text-sm text-gray-800',
                day.format('DD/MM/YY') === dayjs().format('DD/MM/YY') && 'rounded-sm bg-primary text-white'
              )}
            >
              {day.format('ddd D')}
            </span>
          </div>
        ))}
      </div>

      <ScrollArea className="h-[calc(100vh-192px)] overflow-auto">
        {selectAccounts.selectedClients.length !== 0 || selectAccounts.selectedTherapists.length !== 0 ? (
          shifts?.schedules.map((value) => (
            <div
              key={value.therapist.id}
              className="mt-1 grid grid-cols-[224px_repeat(7,_minmax(auto,1fr))] gap-1 border-b border-slate-200 py-2"
            >
              <div className="my-1 flex flex-col items-start justify-start">
                <ScheduleAccount
                  title={compileFullName(value.therapist)}
                  type={value.therapist.role}
                  onClose={() => hideTherapistHandle(value.therapist.id)}
                />
              </div>
              {/* Sun */}
              <div className="flex flex-col items-center justify-start space-y-1 rounded-sm bg-blue-50 p-1">
                {value.appointments[weekDays[0].format('YYYY-MM-DD')] &&
                  value.appointments[weekDays[0].format('YYYY-MM-DD')].map((shift) => (
                    <ShiftItem
                      onPreview={(id) => previewShiftDialogRef.current?.openById(id)}
                      key={shift.id}
                      value={{
                        id: shift.id,
                        title: compileFullName(shift.client),
                        start: shift.startAt
                          ? dayjs(shift.startAt).utcOffset(dayjs().format('Z')).format('h:mm A')
                          : '',
                        end: shift.endAt ? dayjs(shift.endAt).utcOffset(dayjs().format('Z')).format('h:mm A') : '',
                        serviceRole: shift.serviceRole,
                        status: shift.status,
                      }}
                    />
                  ))}
              </div>
              {/* Mon */}
              <div className="flex flex-col items-center justify-start space-y-1 rounded-sm bg-blue-50 p-1">
                {value.appointments[weekDays[1].format('YYYY-MM-DD')] &&
                  value.appointments[weekDays[1].format('YYYY-MM-DD')].map((shift) => (
                    <ShiftItem
                      onPreview={(id) => previewShiftDialogRef.current?.openById(id)}
                      key={shift.id}
                      value={{
                        id: shift.id,
                        title: compileFullName(shift.client),
                        start: shift.startAt
                          ? dayjs(shift.startAt).utcOffset(dayjs().format('Z')).format('h:mm A')
                          : '',
                        end: shift.endAt ? dayjs(shift.endAt).utcOffset(dayjs().format('Z')).format('h:mm A') : '',
                        serviceRole: shift.serviceRole,
                        status: shift.status,
                      }}
                    />
                  ))}
              </div>
              {/* Tue */}
              <div className="flex flex-col items-center justify-start space-y-1 rounded-sm bg-blue-50 p-1">
                {value.appointments[weekDays[2].format('YYYY-MM-DD')] &&
                  value.appointments[weekDays[2].format('YYYY-MM-DD')].map((shift) => (
                    <ShiftItem
                      onPreview={(id) => previewShiftDialogRef.current?.openById(id)}
                      key={shift.id}
                      value={{
                        id: shift.id,
                        title: compileFullName(shift.client),
                        start: shift.startAt
                          ? dayjs(shift.startAt).utcOffset(dayjs().format('Z')).format('h:mm A')
                          : '',
                        end: shift.endAt ? dayjs(shift.endAt).utcOffset(dayjs().format('Z')).format('h:mm A') : '',
                        serviceRole: shift.serviceRole,
                        status: shift.status,
                      }}
                    />
                  ))}
              </div>
              {/* Wed */}
              <div className="flex flex-col items-center justify-start space-y-1 rounded-sm bg-blue-50 p-1">
                {value.appointments[weekDays[3].format('YYYY-MM-DD')] &&
                  value.appointments[weekDays[3].format('YYYY-MM-DD')].map((shift) => (
                    <ShiftItem
                      onPreview={(id) => previewShiftDialogRef.current?.openById(id)}
                      key={shift.id}
                      value={{
                        id: shift.id,
                        title: compileFullName(shift.client),
                        start: shift.startAt
                          ? dayjs(shift.startAt).utcOffset(dayjs().format('Z')).format('h:mm A')
                          : '',
                        end: shift.endAt ? dayjs(shift.endAt).utcOffset(dayjs().format('Z')).format('h:mm A') : '',
                        serviceRole: shift.serviceRole,
                        status: shift.status,
                      }}
                    />
                  ))}
              </div>
              {/* Thu */}
              <div className="flex flex-col items-center justify-start space-y-1 rounded-sm bg-blue-50 p-1">
                {value.appointments[weekDays[4].format('YYYY-MM-DD')] &&
                  value.appointments[weekDays[4].format('YYYY-MM-DD')].map((shift) => (
                    <ShiftItem
                      onPreview={(id) => previewShiftDialogRef.current?.openById(id)}
                      key={shift.id}
                      value={{
                        id: shift.id,
                        title: compileFullName(shift.client),
                        start: shift.startAt
                          ? dayjs(shift.startAt).utcOffset(dayjs().format('Z')).format('h:mm A')
                          : '',
                        end: shift.endAt ? dayjs(shift.endAt).utcOffset(dayjs().format('Z')).format('h:mm A') : '',
                        serviceRole: shift.serviceRole,
                        status: shift.status,
                      }}
                    />
                  ))}
              </div>
              {/* Fri */}
              <div className="flex flex-col items-center justify-start space-y-1 rounded-sm bg-blue-50 p-1">
                {value.appointments[weekDays[5].format('YYYY-MM-DD')] &&
                  value.appointments[weekDays[5].format('YYYY-MM-DD')].map((shift) => (
                    <ShiftItem
                      onPreview={(id) => previewShiftDialogRef.current?.openById(id)}
                      key={shift.id}
                      value={{
                        id: shift.id,
                        title: compileFullName(shift.client),
                        start: shift.startAt
                          ? dayjs(shift.startAt).utcOffset(dayjs().format('Z')).format('h:mm A')
                          : '',
                        end: shift.endAt ? dayjs(shift.endAt).utcOffset(dayjs().format('Z')).format('h:mm A') : '',
                        serviceRole: shift.serviceRole,
                        status: shift.status,
                      }}
                    />
                  ))}
              </div>
              {/* Sat */}
              <div className="flex flex-col items-center justify-start space-y-1 rounded-sm bg-blue-50 p-1">
                {value.appointments[weekDays[6].format('YYYY-MM-DD')] &&
                  value.appointments[weekDays[6].format('YYYY-MM-DD')].map((shift) => (
                    <ShiftItem
                      onPreview={(id) => previewShiftDialogRef.current?.openById(id)}
                      key={shift.id}
                      value={{
                        id: shift.id,
                        title: compileFullName(shift.client),
                        start: shift.startAt
                          ? dayjs(shift.startAt).utcOffset(dayjs().format('Z')).format('h:mm A')
                          : '',
                        end: shift.endAt ? dayjs(shift.endAt).utcOffset(dayjs().format('Z')).format('h:mm A') : '',
                        serviceRole: shift.serviceRole,
                        status: shift.status,
                      }}
                    />
                  ))}
              </div>
            </div>
          ))
        ) : (
          <div className="flex h-[calc(100vh-292px)] w-full flex-col items-center justify-center">
            <p className="text-gray-600">{t('Pages.Schedule.notSelectedAccounts')}</p>
            <Button variant="default" size="sm" className="mt-4 w-full max-w-52" onClick={handleOpenChooseAccounts}>
              {t('Buttons.selectAccounts')} <SquarePlus className="ml-2 size-5 text-white" />
            </Button>
          </div>
        )}
      </ScrollArea>
      <PreviewShiftDialog onUpdated={refetchSchedules} ref={previewShiftDialogRef} />
      <ManageShiftDialog onUpdated={refetchSchedules} ref={manageShiftDialogRef} />
      <SelectAccountsDialog onApply={handleChooseAccounts} ref={selectAccountsDialogRef} />
    </>
  );
}
